.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table.blueTable {
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 100%;
  height: 90%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td, table.blueTable th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table.blueTable tbody td {
  padding: 3px 2px;
  font-size: 14px;
  height: 80%;
}
table.blueTable tr:nth-child(even) {
  background: #D0E4F5;
}
table.blueTable thead {
  background: #1C6EA4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}
table.blueTable thead th {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}
table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}
table.blueTable tfoot td {
  font-size: 10px;
}
table.blueTable td {
  font-size: 14px;
  height: 20px;
}

table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.center-mini {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.swal2-popup {
  font-size: 0.8rem !important;
  font-family: Georgia, serif;
}

.swal2-input {
  height: 4em;
  padding: 1em;
}

.modal-open {
  overflow: hidden;
}

.sw_active { overflow:hidden }

.sweet-alert .swal-text {
  max-height: 6em; /* To be adjusted as you like */
  overflow-y: hidden;
  width: 100%;
}

.swal2-in{
  overflow-y: hidden
}

.swal2-shown{
  overflow-y: hidden
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) { overflow-y: visible !important; }

.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 12px;
}

.lb-lg {
  font-size: 14px;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }
[data-href] { cursor: pointer; }

/* .btn {
  min-width: 30px;
  max-width: 30px;
} */

#Calendar {
  height: 100%;
  width: 80%;
  margin: auto;
  margin-top:50px;
  margin-bottom: 50px;
  /* background: yellow; */
}

.rbc-month-view{
  flex-basis: 500px !important;
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: 22px;
  color: rgb(37, 66, 197)
}

.rbc-date-cell {
  color: rgb(15, 114, 226);
}

.rbc-today {
  background-color: rgb(196, 226, 250) !important;
}
.special-day {
  background-color: #FFD7B3;
}

@media (max-width: 480px) {
  .rbc-toolbar {
    display: block !important;
  }
}
