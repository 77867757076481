.calendars {
	display: grid;
	row-gap: 10rem;
}

.rbc-event-label {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.25sem;
  }

  .rbc-event-label {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.25sem;
  }
